
.currency-logo {
    width: 22px;
    height: 22px;
    border-radius: 50%;

    &--skeleton {
        background: var(--body-light-muted-color);
    }

    &--small {
        width: 18px;
        height: 18px;
        transform: translateY(1.5px);
    }
}
