
.tooltip {
    display: none;
    background: var(--chart-tooltip-background);
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    color: var(--chart-tooltip-color);
    font-size: 13px;
    padding: 7px 10px;
    position: absolute;
    z-index: 99999;
    min-width: 200px;
    pointer-events: none;
}

.chart-tooltip {
    &__body {
        border-spacing: 4px;

        td {
            white-space: nowrap;
        }
    }

    &__label {
        color: var(--body-muted-text-color);
    }

    &__value {
        font-weight: 500;
    }

    &__line-index {
        background: var(--chart-line-green-color);
    }

    &__line-noindex {
        background: var(--chart-line-green-color);
    }

    &__line-up {
        background: var(--chart-line-green-color);
    }

    &__line-down {
        background: var( --chart-line-red-color);
    }
}
